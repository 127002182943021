<template>
  <div>
     <!-- 查询表单 -->
     <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <div class="main-page-table__header">
      <base-button
        label="上传"
        btnType="upload"
        accept=".xlsx"
        icon="iconfont iconshangchuan"
        action="#"
        :http-request="(row) =>{
            return Upload(row);
          }
        "
      />
      <base-button
        label="模板下载"
        style="margin: 0px 10px"
        action="#"
        icon="iconfont iconxiazai"
        @click="downloadFile(companyFileId)"
      />
    </div>
    <!-- 表格 -->
    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
        border: false
      }"
      :queryParas="queryParas"
      :dataSource.sync="tableData"
      ref="tableData"
      :api="api"
      :getApi="'opinionPage'"
      :loadCount="loadCount"
      :webPage="false"
    >
    <template slot="action" slot-scope="scope">
      <icon-button
        content="下载"
        icon="iconfont iconxiazai"
        v-if="scope.row.status == 1"
        @click="downloadFile(scope.row.excId)"
      />
    </template>
    </base-table>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { formConfig, tableConfig } from './utils/config'
import { opinionApi, supplierApi } from '@/api/companyApi'
import { getDictLists } from '@/filters/fromDict'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  data () {
    return {
      queryParas: {
        pageIndex: 1,
        pageSize: 10
      },
      loadCount: 0,
      tableData: [],
      gysData: []
    }
  },
  components: {
    BaseForm,
    BaseTable,
    BaseButton,
    IconButton
  },
  computed: {
    formConfig () {
      return formConfig(this)
    },
    columns () {
      return tableConfig(this)
    },
    api () {
      return opinionApi
    },
    companyFileId () {
      return getDictLists('COMPANY_SEL_ID') ? getDictLists('COMPANY_SEL_ID')[0].dictId : ''
    }
  },
  watch: {
    'queryParas.queryTime': {
      handler (val) {
        if (val && val.length > 0) {
          this.$set(this.queryParas, 'selDateTimeStart', val[0])
          this.$set(this.queryParas, 'selDateTimeEnd', val[1] + ' 23:59:59')
        } else {
          this.$set(this.queryParas, 'selDateTimeStart', '')
          this.$set(this.queryParas, 'selDateTimeEnd', '')
        }
      }
    }
  },
  mounted () {
    this.gysList()
    this.handleFilter()
  },
  methods: {
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then(res => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    // 查询表格数据
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    // 清除查询条件
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10
      }
    },
    // 上传文件
    Upload (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      if (param.file.name.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = ['xlsx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        return this.warning('请上传xlsx格式文件')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      this.api.opinionFileExcel(formData).then((res) => {
        // 文件导入出现问题
        if (res.data) {
          this.$stateConfirm({ title: '提示', message: '文件上传失败，点击确定下载说明文件', show: true, cancelButtonText: '关闭', type: 'warning' })
            .then(() => {
              this.downloadFile(res.data)
            }).catch(() => {})
        } else {
          this.$stateConfirm({ title: '提示', message: '文件上传成功，正在查询', show: true, cancelButtonText: '关闭', showConfirmButton: false, type: 'success' })
            .then(() => {
            }).catch(() => {})
          this.handleFilter()
        }
      })
    },
    // 下载模板文件
    downloadFile (fileId) {
      if (fileId) {
        const params = { keyId: fileId }
        downAPi.download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" src="./index.scss" scoped></style>
