var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.formConfig,"formAttrs":{
      model: _vm.queryParas,
      labelWidth: '90px'
    }},on:{"handleFilter":_vm.handleFilter,"clearParams":_vm.clearParams}}),_c('div',{staticClass:"main-page-table__header"},[_c('base-button',{attrs:{"label":"上传","btnType":"upload","accept":".xlsx","icon":"iconfont iconshangchuan","action":"#","http-request":function (row) {
          return _vm.Upload(row);
        }}}),_c('base-button',{staticStyle:{"margin":"0px 10px"},attrs:{"label":"模板下载","action":"#","icon":"iconfont iconxiazai"},on:{"click":function($event){return _vm.downloadFile(_vm.companyFileId)}}})],1),_c('base-table',{ref:"tableData",staticClass:"main-page-table with-add",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
      stripe: true,
      border: false
    },"queryParas":_vm.queryParas,"dataSource":_vm.tableData,"api":_vm.api,"getApi":'opinionPage',"loadCount":_vm.loadCount,"webPage":false},on:{"update:dataSource":function($event){_vm.tableData=$event},"update:data-source":function($event){_vm.tableData=$event}},scopedSlots:_vm._u([{key:"action",fn:function(scope){return [(scope.row.status == 1)?_c('icon-button',{attrs:{"content":"下载","icon":"iconfont iconxiazai"},on:{"click":function($event){return _vm.downloadFile(scope.row.excId)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }