<template>
  <div>
    <el-input
      :value="value"
      v-bind="$attrs"
      v-on="currentListeners"
    ></el-input>
  </div>
</template>

<script>
import { EmojiReg } from '@/config/reg-config'
export default {
  name: 'numberInput',
  props: {
    value: {}
  },
  dat () {
    return {
      input: ''
    }
  },
  computed: {
    currentListeners () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput
      })
    }
  },
  methods: {

    handleInput (e) {
      // 替换掉表情输入
      let value = e.replace(EmojiReg, '')
      // 替换 首位为0 的字符
      const numberarr = value.split('')
      if (
        numberarr[0] === '0' &&
        numberarr.length > 1
      ) {
        return
      }
      // 只能为数字
      value = value
        .replace(/[^\d]/g, '') // 清除“数字”和“.”以外的字符
      this.$emit('input', value)
    }
  }
}
</script>
