import BaseSelect from '@/components/common/base-select/base-select.vue'
import NumberInput from '@/components/input/number-input/number-input'
// 查询表单
export const formConfig = (content) => {
  return [
    {
      label: '批次',
      prop: 'batchNUm',
      attrs: {
        placeholder: '请输入',
        maxlength: 9
      },
      tag: NumberInput
    },
    {
      label: '供应商',
      prop: 'companyName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['enterpriseName', 'enterpriseName']
      },
      tag: BaseSelect
    },
    {
      label: '查询时间',
      prop: 'queryTime',
      attrs: {
        type: 'daterange',
        'range-separator': '至',
        'start-placeholder': '开始时间',
        'end-placeholder': '结束时间',
        'value-format': 'yyyy-MM-dd'
      },
      tag: 'el-date-picker'
    }
  ]
}
// 表格数据
export const tableConfig = (content) => {
  return [
    {
      label: '批次',
      prop: 'batchNum',
      width: '100px'
    },
    {
      label: '供应商名单',
      prop: 'companyList',
      align: 'left'
    },
    {
      label: '供应商数量',
      prop: 'companyNum',
      width: '120px'
    },
    {
      label: '舆情数量',
      prop: 'opinionNum',
      width: '120px',
      formatter: data => {
        return (data.status === '0' || data.status === '2') ? '-' : data.opinionNum
      }
    },
    {
      label: '查询状态',
      prop: 'status',
      width: '120px',
      formatter: data => {
        return data.status === '0' ? '查询中' : (data.status === '1' ? '查询完成' : '查询失败')
      }
    },
    {
      label: '查询时间',
      prop: 'selDateTime',
      width: '140px'
    },
    {
      label: '操作',
      prop: 'action',
      width: '120px'
    }
  ]
}
